import React, { ChangeEvent, useEffect, useState } from 'react';
import { Alert, Button, Card, Flex, Loader } from '@aws-amplify/ui-react';
import { useParams } from 'react-router-dom'

import { IGQLClient } from '../../client/gqlts';
import * as codegenapi from '../../graphql/API';

interface IProps {
    activitytypeid: string,
    cancelAddRefData: () => void,
    gqlClient: IGQLClient
    siteid: string | null,
}

const NewReferenceData = (props: IProps): JSX.Element => {

    const { studyid, subjectid } = useParams();

    const [selectedFile, setSelectedFile] = useState<File | undefined>();

    const [fileError, setFileError] = useState<string>('');
    const [uploadingStatus, setUploadingStatus] = useState<string>('idle');
    const [uploadingPercentage, setUploadingPercentage] = useState<number>(0);
    const [timerPercentage, setTimerPercentage] = useState<number>(0);

    //  destructure locally for use in useEffect
    const { cancelAddRefData } = props;

    useEffect(() => {
        if (timerPercentage === 100)
            cancelAddRefData();
    }, [cancelAddRefData, timerPercentage]);

    useEffect(() => {
        if (uploadingStatus === 'complete')
            setInterval(
                () => {
                    setTimerPercentage(prevState => prevState !== 100 ? prevState + 2 : prevState)
                },
                50
            );
    }, [uploadingStatus]);

    function twoStepUpload() {
        setUploadingStatus('gettingurl');

        if (selectedFile)
            step1AddReferenceData(props.activitytypeid, selectedFile)
                .then((response) => {
                    setUploadingStatus('uploading');
                    if ((response as any).addReferenceData)
                        step2UploadFile(
                            selectedFile,
                            (response as any).addReferenceData.objectUrl,
                            (response as any).addReferenceData.fields)
                            .then((_response) => {
                                setUploadingStatus('complete');
                            })
                            .catch((response) => {
                                setUploadingStatus('idle');
                                console.error(response.errors);
                            })
                    else throw Error("Empty response from API. This shouldn't happen");
                })
                .catch((response) => {
                    setUploadingStatus('idle');
                    console.error(response.errors);
                })
    }

    async function step1AddReferenceData(activitytypeid: string, file: File): Promise<codegenapi.GetReferenceDataQuery> {
        if (props.siteid && studyid && subjectid)
            return props.gqlClient.gqlAddReferenceData(props.siteid, studyid, subjectid, activitytypeid, file.name);
        else
            throw Error("Empty siteid or subjectid. This shouldn't happen");
    }

    async function step2UploadFile(file: File, objectUrl: string, fields: string) {
        const formData = new FormData();
        fields
            .replace("{", "").replace("}", "").split(',')           //  TODO: remove this, agree on a "typed" way of retrieving fields from API
            .map((field) =>
                //  append all fields from the pre-signed URL to the form
                formData.append(
                    field.split('=')[0],                            //  TODO: this shouldn't require string processing
                    field.replace(field.split('=')[0] + '=', ""))   //  TODO: this shouldn't require string processing
            );

        //  append file contents
        formData.append('file', file);

        //  BACK-127
        const xhr = new XMLHttpRequest();
        return new Promise((resolve) => {
            xhr.open("POST", objectUrl, true);
            xhr.upload.addEventListener("progress", (event) => {
                if (event.lengthComputable) {
                    setUploadingPercentage(Math.floor(100 * event.loaded / event.total));
                }
            });
            xhr.addEventListener("loadend", () => {
                resolve(xhr.readyState === 4 && xhr.status === 200);
            });
            xhr.send(formData);
        });
    }

    function handleFileSelected(e: ChangeEvent<HTMLInputElement>) {

        e.preventDefault();

        if (e.target.files) {
            const file = e.target.files[0];
            if (file.size < 150 * 1024 * 1024) {
                setFileError('');
                setSelectedFile(file);
            }
            else
                setFileError("Max. file size is 150MB");
        }
        else
            throw Error("No files in file selection. This shouldn't happen.");
    }

    function handleAlertDismiss() {
        props.cancelAddRefData();
    }

    return (
        <Flex>
            <h1>Upload reference data</h1>
            <Flex>
                <Card style={{ width: '100%' }}>
                    <input
                        style={{ margin: "1rem 0 0 0" }}
                        type="file"
                        onChange={handleFileSelected} />
                    {
                        fileError &&
                        <Alert
                            style={{ margin: "1rem 0 0 0" }}
                            isDismissible={false}
                            hasIcon={true}
                            variation="error"
                        >
                            <pre>{fileError}</pre>
                        </Alert>
                    }
                    {
                        uploadingStatus !== 'idle' &&
                        <Alert
                            style={{ margin: "1rem 0 0 0" }}
                            isDismissible={uploadingStatus === 'complete' ? true : false}
                            onDismiss={handleAlertDismiss}
                            hasIcon={true}
                            variation={uploadingStatus === 'complete' ? "success" : "info"}
                        >
                            {uploadingStatus === 'gettingurl' && 'Preparing upload URL...'}
                            {uploadingStatus === 'uploading' && 'Uploading...'}
                            {uploadingStatus === 'complete' && 'Upload complete!'}
                            <Loader
                                isDeterminate={uploadingStatus === 'uploading'}
                                percentage={uploadingPercentage}
                                style={{ margin: "1rem 0 0 0", display: (uploadingStatus === 'idle' || uploadingStatus === 'complete') ? 'none' : '' }}
                                variation="linear"
                            />
                            <Loader
                                isDeterminate={true}
                                percentage={timerPercentage}
                                isPercentageTextHidden={true}
                                style={{ margin: "1rem 0 0 0", display: (uploadingStatus === 'complete') ? '' : 'none' }}
                                variation="linear"
                            />
                        </Alert>
                    }
                </Card>
                <Flex className='flex-row'>
                    <Button
                        disabled={!(selectedFile && uploadingStatus !== 'complete')}
                        onClick={() => twoStepUpload()}
                        style={{ flexGrow: 3 }}
                        variation="primary"
                    >
                        Upload
                    </Button>
                    <Button
                        onClick={() => props.cancelAddRefData()}
                        style={{ flexGrow: 1 }}
                        variation="primary"
                    >
                        Cancel
                    </Button>
                </Flex>
            </Flex>
        </Flex >
    )
}

export default NewReferenceData;
