import { Observable, Subscription } from 'rxjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { Alert, Flex } from '@aws-amplify/ui-react';

import ActivitiesList from '../components/activities/list';
import NewReferenceData from '../components/referenceData/new';
import ReferenceDataList from '../components/referenceData/list';

import { IGQLClient } from '../client/gqlts';
import * as codegenapi from '../graphql/API';

interface IProps {
    date: number | undefined,
    gqlClient: IGQLClient,
    setErrors: React.Dispatch<React.SetStateAction<string[] | undefined>>,
    siteid: string | null,
    subject: codegenapi.Subject | undefined,
}

const Activities = (props: IProps): JSX.Element => {

    const { studyid } = useParams();

    const { setErrors } = props;

    const [activities, setActivities] = useState<codegenapi.Activity[]>();
    const [activityTypes, setActivityTypes] = useState<codegenapi.ActivityType[]>();

    //  selects for which activity we want to operate reference data
    const [editingRefDataActivity, setEditingRefDataActivity] = useState<codegenapi.Activity>();

    //  selects the reference data to operate on
    const [editingRefData, setEditingRefData] = useState<codegenapi.ReferenceData[]>();

    //  state for new reference data
    const [newReferenceData, setNewReferenceData] = useState<codegenapi.ReferenceData>();

    //  use callback to share the function with two 'useEffect'
    const refreshActivities = useCallback(async (subjectid: string) => {
        if (studyid) {

            //  call in parallel
            const activitiesCall = props.gqlClient.gqlListActivities(subjectid)
                .catch(response => setErrors(prevState => prevState ? prevState.concat(response.errors) : response.errors))
            const activityTypesCall = props.gqlClient.gqlListActivityTypes(studyid)
                .catch(response => setErrors(prevState => prevState ? prevState.concat(response.errors) : response.errors))

            //  initialize persisted activities
            const persistedActivities = (await activitiesCall)?.listActivities as codegenapi.Activity[];

            //  initialize activity types
            const studyActivityTypes = (await activityTypesCall)?.listActivityTypes as codegenapi.ActivityType[];
            setActivityTypes(studyActivityTypes);

            //  pre-fill activities
            const prefilledActivities: codegenapi.Activity[] = [];
            if (studyActivityTypes)
                for (const at of studyActivityTypes)
                    if (!persistedActivities.some(act => act.activitytypeid === at.activitytypeid)) {
                        const prefilledActivity = {
                            activitytypeid: at.activitytypeid,
                            activitytype: at,
                            subjectid: subjectid,
                        } as codegenapi.Activity;
                        prefilledActivities.push(prefilledActivity);
                    }

            setActivities(prefilledActivities.concat(persistedActivities));
        }

    }, [props.gqlClient, studyid, setErrors]);

    //  update ref. data activity being edited only when needed
    useEffect(() => {
        if (activities && editingRefDataActivity && editingRefDataActivity.activitytypeid)
            setEditingRefDataActivity(activities?.find((activity: codegenapi.Activity) => activity.activitytypeid === editingRefDataActivity?.activitytypeid));
    }, [activities, editingRefDataActivity]);

    //  updates list of activities
    useEffect(() => {

        let activitiesSubscription: Subscription;

        if (props.subject?.subjectid) {

            refreshActivities(props.subject.subjectid);

            props.gqlClient.gqlOnActivitiesUpdatesSubscription(props.subject.subjectid)
                .then(subscription => {
                    activitiesSubscription = (subscription as Observable<codegenapi.OnActivitiesUpdatesSubscription>).subscribe({
                        next: () => {
                            //  The data returned by the subscrition isn't complete
                            if (props.subject?.subjectid)
                                refreshActivities(props.subject.subjectid);
                        },
                        error: error => console.warn(error)
                    })
                })
                .catch(error => {
                    console.error(error);
                });
        }

        return () => {
            activitiesSubscription?.unsubscribe();
        }

    }, [props.gqlClient, props.subject, refreshActivities]);

    //  updates list of reference data
    useEffect(() => {

        let referenceDataSubscription: Subscription;

        if (editingRefDataActivity && props.subject?.subjectid) {

            setEditingRefData(editingRefDataActivity.referenceData ?? []);

            props.gqlClient.gqlOnReferenceDataUpdatesSubscription(props.subject.subjectid, editingRefDataActivity.activitytypeid)
                .then(subscription => {
                    referenceDataSubscription = (subscription as Observable<codegenapi.OnReferenceDataUpdatesSubscription>).subscribe({
                        next: () => {
                            //  The data returned by the subscrition isn't complete
                            if (props.subject?.subjectid)
                                refreshActivities(props.subject?.subjectid);
                        },
                        error: error => console.warn(error)
                    })
                })
                .catch(error => {
                    console.error(error);
                });
        }

        return () => {
            referenceDataSubscription?.unsubscribe();
        }

    }, [editingRefDataActivity, props.gqlClient, props.subject?.subjectid, refreshActivities])

    return (
        <>
            <h1>Activities</h1>
            {
                !props.date &&
                <Alert
                    variation="warning"
                >
                    <b>A date must be saved before adding activities</b>
                </Alert>
            }
            {
                props.date &&
                <>
                    <ActivitiesList
                        activities={activities}
                        activityTypes={activityTypes}
                        date={props.date}
                        editingReferenceDataActivityType={editingRefDataActivity?.activitytypeid}
                        gqlClient={props.gqlClient}
                        setEditingRefDataActivity={setEditingRefDataActivity}
                        siteid={props.siteid}
                        subject={props.subject}
                    />
                    {
                        editingRefDataActivity &&
                        <Flex className="flex-row">
                            <ReferenceDataList
                                activitytypeid={editingRefDataActivity.activitytypeid}
                                cancelRefData={() => setEditingRefDataActivity(undefined)}
                                referenceData={editingRefData}
                                gqlClient={props.gqlClient}
                                setNewReferenceData={() => setNewReferenceData({} as codegenapi.ReferenceData)}
                                siteid={props.siteid}
                                subject={props.subject}
                            />
                            {
                                editingRefDataActivity && newReferenceData &&
                                <NewReferenceData
                                    activitytypeid={editingRefDataActivity.activitytypeid}
                                    cancelAddRefData={() => setNewReferenceData(undefined)}
                                    gqlClient={props.gqlClient}
                                    siteid={props.siteid}
                                />
                            }
                        </Flex>
                    }
                </>
            }
        </>
    )
}

export default Activities;
