import React, { useEffect, useState } from 'react';
import { Alert, Button, Flex } from '@aws-amplify/ui-react';
import { Route, Routes, useParams } from 'react-router-dom'
import { IGQLClient } from '../../client/gqlts';

import Activities from '../../pages/Activities';
import Forms from '../../pages/Forms';
import SubjectDevices from './devices';

import * as codegenapi from '../../graphql/API';

interface IProps {
    gqlClient: IGQLClient,
    setErrors: React.Dispatch<React.SetStateAction<string[] | undefined>>;
    siteid: string,
    subjects: codegenapi.Subject[] | undefined,
    study: codegenapi.Study | undefined,
}

const Subject = (props: IProps): JSX.Element => {

    const { subjectid } = useParams();

    const [subject, setSubject] = useState<codegenapi.Subject>({} as codegenapi.Subject);
    const [devices, setDevices] = useState<codegenapi.SubjectDevice[]>();
    const [date, setDate] = useState<number>();

    const [singleFormtype, setSingleFormtype] = useState<codegenapi.FormType>();

    useEffect(() => {
        setSubject(props.subjects?.find(subject => subject.subjectid === subjectid) ?? {} as codegenapi.Subject);
    }, [props, subjectid]);

    useEffect(() => {
        setDevices(subject?.devices as codegenapi.SubjectDevice[]);
    }, [subject]);

    useEffect(() => {
        if (props.study && props.study.formtypes?.length === 1)
            setSingleFormtype(props.study.formtypes[0]);
    }, [props.study]);

    async function addSubjectDevice(newSubjectDevice: codegenapi.SubjectDevice): Promise<void> {
        const newDevices = devices ? devices.concat([newSubjectDevice]) : [newSubjectDevice];
        return props.gqlClient.gqlUpdateSubject(
            subject.siteid,
            subject.studyid,
            subject.subjectid,
            newDevices)
            .then(_response => {
                setDevices(newDevices);
            })
            .catch(response => {
                props.setErrors(prevState => prevState ? prevState.concat(response.errors) : response.errors)
            });
    }

    async function removeSubjectDevice(subjectDevice: codegenapi.SubjectDevice): Promise<void> {
        const newDevices = devices?.filter(
            device => !(device.deviceid === subjectDevice.deviceid
                && device.position === subjectDevice.position));
        return props.gqlClient.gqlUpdateSubject(
            subject.siteid,
            subject.studyid,
            subject.subjectid,
            newDevices)
            .then(_response => {
                setDevices(newDevices);
            })
            .catch(response => {
                props.setErrors(prevState => prevState ? prevState.concat(response.errors) : response.errors)
            })
    }

    async function closeAndSubmit() {
        props.gqlClient.gqlGetSubjectSnapshot(subject.studyid, subject.subjectid)
            .then(response => {

                try {
                    //  show error when there are no activities
                    if (!response.getSubject?.activities || response.getSubject.activities.length === 0)
                        throw Error("At least one activity must be saved before submitting and closing a subject");

                    //  resolve latest stopTime
                    const latestStopTime = response.getSubject?.activities?.sort((a, b) => b.stopTime - a.stopTime)[0].stopTime;

                    //  order activities by startTime
                    response.getSubject?.activities?.sort((a, b) => a.startTime - b.startTime);

                    //  add firstStartTime and lastStopTime fields
                    const snapshot = {
                        ...response.getSubject,
                        earliestStartTime: response.getSubject?.activities?.at(0)?.startTime,
                        latestStopTime: latestStopTime,
                        timezoneOffset: new Date().getTimezoneOffset() * 60,
                    };

                    //  serialize and submit
                    const snapshotStr = JSON.stringify(snapshot)
                    props.gqlClient.gqlCloseSubject(
                        subject.siteid,
                        subject.studyid,
                        subject.subjectid,
                        subject.devices,
                        snapshotStr)
                        .catch(response => {
                            props.setErrors(prevState => prevState ? prevState.concat(response.errors) : response.errors)
                        });

                }
                catch (e) {
                    if (e instanceof Error) {
                        const error = e as Error;
                        props.setErrors(prevState => prevState ? prevState.concat(error.message) : [error.message]);
                    } else if (typeof e === "string")
                        props.setErrors(prevState => prevState ? prevState.concat(e as string) : [e as string]);
                }
            })
            .catch(response => {
                props.setErrors(prevState => prevState ? prevState.concat(response.errors) : response.errors)
            });
    }

    return (
        <Flex>
            <h1>Subject: {subject?.subjectid}</h1>
            <Routes>
                <Route
                    path="*"
                    element={
                        <>
                            <Flex className="flex-row">
                                <Forms
                                    date={date}
                                    setDate={setDate}
                                    gqlClient={props.gqlClient}
                                    singleFormType={singleFormtype}
                                    siteid={props.siteid}
                                    subject={subject}
                                />
                                <SubjectDevices
                                    addSubjectDevice={addSubjectDevice}
                                    devices={devices}
                                    gqlClient={props.gqlClient}
                                    removeSubjectDevice={removeSubjectDevice}
                                    siteid={props.siteid}
                                    subject={subject}
                                />
                            </Flex>
                            <Flex>
                                <Activities
                                    date={date}
                                    gqlClient={props.gqlClient}
                                    setErrors={props.setErrors}
                                    siteid={props.siteid}
                                    subject={subject}
                                />
                            </Flex>
                            <Flex>
                                <h1>
                                    Subject status: <span
                                        style={{ color: subject.status === codegenapi.SubjectStatusType.OPEN ? 'green' : 'darkred' }}
                                    >{subject.status}
                                    </span>
                                </h1>
                                {
                                    subject.status === codegenapi.SubjectStatusType.OPEN &&
                                    <>
                                        <Alert
                                            variation="info"
                                        >
                                            <b>Once submitted changes are not allowed</b>
                                            <br />Ensure that all forms, devices and activities are complete
                                            <br />before submitting!</Alert>
                                        <Button
                                            onClick={closeAndSubmit}
                                            title="Send"
                                            variation="primary"
                                        >
                                            SUBMIT AND CLOSE
                                        </Button>
                                    </>
                                }
                            </Flex>
                        </>
                    }
                />
                < Route
                    path="activities/*"
                    element={
                        < Activities
                            date={date}
                            gqlClient={props.gqlClient}
                            setErrors={props.setErrors}
                            siteid={props.siteid}
                            subject={subject}
                        />
                    }
                />
                < Route
                    path="devices/*"
                    element={
                        < SubjectDevices
                            addSubjectDevice={addSubjectDevice}
                            devices={devices}
                            gqlClient={props.gqlClient}
                            siteid={props.siteid}
                            subject={subject}
                            removeSubjectDevice={removeSubjectDevice}
                        />
                    }
                />
                < Route
                    path="formtypes/*"
                    element={
                        < Forms
                            date={date}
                            setDate={setDate}
                            gqlClient={props.gqlClient}
                            singleFormType={singleFormtype}
                            siteid={props.siteid}
                            subject={subject}
                        />
                    }
                />
            </Routes >
        </Flex >
    )
}

export default Subject;
